<template>
	<ExportReportActionPlan />
</template>
<script>

import ExportReportActionPlan from '@/components/action-plans/export-report-action-plan/index.vue'

export default {
	name: 'ExportReportActionPlanPage',
	components: { ExportReportActionPlan },
};
</script>
